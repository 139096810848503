import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import PageEN from './pagesEN';
import PagePT from './pagesPT';

function App() {
  useEffect(() => {
    window.location.href = 'http://lattes.cnpq.br/4548346554369389';
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectToExternal />} />
        {/*<Route path="/" element={<PageEN />} />
      <Route path="en" element={<PageEN />} />
      <Route path="pt" element={<PagePT />} />*/}
      </Routes>
    </BrowserRouter>
  );
}

function RedirectToExternal() {
  useEffect(() => {
    window.location.href = 'https://lattes.cnpq.br/4548346554369389';
  }, []);

  return null;
}

export default App;